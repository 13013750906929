@font-face{
    font-family:'comfortaa';
    font-weight:normal;
    font-style:normal;
    src:url('../fonts/comfortaa.ttf');
    src:url('../fonts/comfortaa.woff2') format('woff2');
}

.logo {
    height: 40px;
}
