/*

TemplateMo 561 Purple Buzz

https://templatemo.com/tm-561-purple-buzz

---------------------------------------------
Table of contents
------------------------------------------------
1. Typography
2. Main Nav
3. Home
4. About
5. Work
6. Single Work
7. Pricing
8. Contact
--------------------------------------------- */

html {font-family: 'Open Sans', sans-serif;}
/* Typography */
p, a {font-weight: 400;}
.h2 {font-size: 40px;}
.h3 {font-size: 28px;}
.light-300 {
  font-family: 'comfortaa', sans-serif !important;
  font-weight: 300;
}
.regular-400 {
  font-family: 'comfortaa', sans-serif !important;
  font-weight: 400;
}
.semi-bold-600 {
  font-family: 'comfortaa', sans-serif !important;
  font-weight: 600;
}
.typo-space-line::after,
.typo-space-line-center::after {
  content: "";
  width: 150px;
  display:block;
  position: absolute;
  border-bottom:  5px solid #70bf9b;
  padding-top: .5em;
}
.typo-space-line-center::after {
  left: 50%;
  margin-left: -75px;
}
/* Main Nav */
#main_nav .nav-item .nav-link:hover {color: #fff;}
/* Home */
#index_banner {
  background-image: none;
  background-position: center center; background-size: cover;
  height: 100%;
  min-height: 60vh;
  width: 100%;
}
#index_banner .carousel-blur-bg {
  background-color: rgba(128,128,128,0.4);
  border-radius: 10px;
  backdrop-filter: blur(6px);
}
#index_banner .banner-body {
  color: #ffffff;
}
#index_banner .carousel-item {
  height: 80vh;
  background-position: top center; background-size: cover;
}
#index_banner .carousel-control-prev i,
#index_banner .carousel-control-next i {
  color: #70bf9b !important;
  text-decoration: none;
  font-size: 4em;
}
#index_banner .carousel-inner {
  height: 80vh;
}
#index_banner .carousel-indicators li,
#index_banner .carousel-indicators.active {
  background-color: #8076db !important;
}
.service-wrapper .service-footer {
  max-width: 720px;
}
.service-work.card {
  border-radius: 10px !important;
  cursor: pointer;
}
.service-work .service-work-vertical {
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 33.33%, rgba(255,255,255,1) 66.66%, rgba(255,255,255,0) 99.99%);
  background-size: 300% 300%;
  background-position: 0% 100%;
  transition: .5s;
}
.service-work .service-work-vertical:hover {
  background-position: 0% 0%;
  transition: .5s;
}
.service-work .service-work-vertical:hover * {
  color: #000;
  border-color: #000;
  transition: .5s;
}
.recent-work.card{
  border-radius: 10px !important;
}
.recent-work .recent-work-vertical {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 33.333%, rgba(97,84,199,1) 66.666%, rgba(255,255,255,0) 99.999%);
  background-size: 300% 300%;
  background-position: 0% 100%;
  transition: .5s;
  cursor: pointer;
}
.recent-work .recent-work-vertical:hover {
  background-position: 0% 0%;
  transition: .5s;
}
.recent-work .recent-work-vertical:hover div.recent-work-content {
  top: 50%;
  margin-top: -2.5em;
  position: absolute;
}
.recent-work .recent-work-vertical:hover * {
  color: white;
  transition: .5s;
}
/* About */
#work_banner {
  background-image: none;
  background-position: center center; background-size: cover;
  height: 100%;
  width: 100%;
}
.progress {
  height: 8px;
}
.objective-icon {
  border-radius: 13px;
  width: 8rem;
}
.partner-wap {
  background-color: #7276ec;
  transition: 1s;
  cursor: pointer;
}
.partner-wap:hover {
  background-color: #b1b3f5;
}
.why-us {
  background: rgb(239,239,253);
  background: -moz-linear-gradient(0deg, rgba(239,239,253,1) 50%, rgba(255,255,255,1) 50%);
  background: -webkit-linear-gradient(0deg, rgba(239,239,253,1) 50%, rgba(255,255,255,1) 50%);
  background: linear-gradient(0deg, rgba(239,239,253,1) 50%, rgba(255,255,255,1) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#efeffd",endColorstr="#ffffff",GradientType=1);
}
.team-member-img {
  transition-duration: 0.15s;
}
.team-member:hover .team-member-img {
  padding: 10px !important;
}

/* Work */
/* Singel Work */
#work_single_banner {
  background-image: none;
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.related-content {
  border-radius: 20px;
  cursor: pointer;
}
/* Pricing */
.pricing-list {
  margin-bottom: 1px;
}
.pricing-table {
  max-width: 350px;
  margin: auto;
}
.pricing-table,
.pricing-horizontal {
  border-radius: 15px !important;
}
/* Contact */
#floatingtextarea {
  height: 150px
}
/* Footer */
footer a.text-light:hover {
  color: #70bf9b !important;
  transition-duration: 0.15s;
}

.btn-form {
  background-color: #70bf9b;
  border: #70bf9b 1px;
  border-radius: 50rem;
  height: 40px;
  color: white;
}

.btn-form:hover {
  background-color: var(--bs-primary);
  color: white;
}
